export class BaseApi {

    /**
     * @constructor
     */
    constructor() {
        this.currentDomainZone = document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];

        this.baseUrl = this.currentDomainZone === 'com'
            ? 'https://api.neologistics.it/picker/'
            : 'https://api_stage.neologistics.it/picker/';

        this.config = {
            //withCredentials: true
        };
    }

    /**
     * Prepare request headers
     */
    prepareRequest() {
        this.config.headers = {
            'content-Type': 'application/json',
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${WV.store.getters['auth/getToken']}`,
        };
        axios.defaults.withCredentials = true;
    }

    /**
     * GET request
     * @method get
     * @param {string} endpoint - URL
     */
    get(endpoint) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * POST request
     * @method post
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    post(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.post(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * PUT request
     * @method put
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    put(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.put(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * Delete request
     * @method delete
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    delete(endpoint, payload) {
        this.prepareRequest();
        this.config.data = payload;
        return new Promise((resolve, reject) => {
            axios.delete(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }


    requestRejected(error) {
        if (typeof error.response.status !== 'undefined') {

            if (error.response.status === 401) {
                console.log('API Webvork logged out');
                console.log(error.response.data.message);
                WV.store.dispatch('auth/logout').then(() => {
                    WV.router.push("/");
                })
            }
            if (error.response.status === 500) {
                WV.toast.error(error.response.data.message, 10000);
            }

        }
    }

}

export default class Api extends BaseApi {
    constructor() {
        super();
    }

    /**
     * @param {String} email
     * @param {String} password
     */
    postLogin(email, password) {
        return this.post('v1/auth/login', {email: email, password: password});
    }

    getProfile() {
        return this.get(`v1/profile`);
    }

    updateProfile(profile) {
        return this.post(`v1/profile`, profile);
    }

    getWarehouses() {
        return this.get(`v1/warehouses`);
    }

    getWarehousesAvailableForPicker() {
        return this.get(`v1/warehouses?available_for_picker=true`);
    }

    getWarehousePrinters(warehouseId) {
        return this.get(`v1/warehouse-printers?warehouse_id=${warehouseId}`);
    }

    getPreorders() {
        return this.get(`v1/preorders`);
    }

    postPreorderAcceptance(acceptance) {
        return this.post(`v1/preorders/acceptance`, acceptance);
    }

    /**
     * @param {Array} gtins Array of gtins
     */
    getProducts(gtins) {
        return this.get(`v1/products?gtins=${gtins.join(',')}`);
    }


    getOrderForPicking(orderId) {
        return this.get(`v1/orders/${orderId}`);
    }

    markOrderAsPicked(orderId) {
        return this.post(`v1/orders/picked`, {order_id: orderId});
    }

    markOrderAsRejected(orderId) {
        return this.post(`v1/orders/rejected`, {order_id: orderId});
    }

}
